import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'

export const useBankStore = defineStore('bankStore', () => {
    const fetcher = useCustomFetch()

    const userBankAccounts = ref([])
    const bankList = ref([])

    const getBanks = async () => {
        try {
            const { data } = await fetcher.get('/payment/indexdeposit')
            if (data?.value?.status === 'OK') {
                bankList.value = data.value.data?.banks
            }
        } catch (error) {
            console.log(error)
        }
    }
    const createUserBank = async (payload) => {
        try {
            const { data, pending, error } = await fetcher.post(
                '/account/createBank',
                {
                    ...payload,
                }
            )
            return { data, pending, error }
        } catch (error) {
            console.log(error)
        }
    }
    const getUserBankAccounts = async () => {
        try {
            const { data, pending, error } =
                await fetcher.get('/account/userbank')
            if (data?.value?.status === 'OK') {
                userBankAccounts.value = data.value.data
                return { data: userBankAccounts, pending, error }
            }
            return data
        } catch (error) {
            // todo show error message
            console.log(error)
        }
    }

    const userVerifiedBanks = ref([])
    const getUserVerifiedBanks = async () => {
        try {
            const { data } = await fetcher.get('/payment/indexdeposit')
            if (data?.value?.status === 'OK') {
                userVerifiedBanks.value = data.value.data?.userBanks
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        userBankAccounts,
        bankList,
        getBanks,
        createUserBank,
        getUserBankAccounts,

        //
        userVerifiedBanks,
        getUserVerifiedBanks,
    }
})
